<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <span>选择组织：</span>
        <tchtree v-model="deptId" @change="searchData" @changeType="changeType"></tchtree>

        <span v-if="$store.state.pmid == 215">届次：</span>
        <el-select v-if="$store.state.pmid == 215" clearable v-model="searchForm.school_year" size="small"
                   placeholder="请选择" style="max-width:200px;" @change="searchData">
          <el-option v-for="item in schoolYears" :key="item.value" :label="item.title" :value="item.value">
          </el-option>
        </el-select>

        <span :class="$store.state.pmid == 215 ? 'ml10' : ''">请输入关键字：</span>
        <el-input v-model="searchForm.keyword" size="small" placeholder="请输入关键字，多关键字使用空格隔开" clearable
                  style="width: 200px;"/>

        <span class="ml10">状态：</span>
        <el-select v-model="searchForm.biye" size="small" style="width:100px;">
          <el-option :value="0" label="未毕业"></el-option>
          <el-option :value="1" label="已毕业"></el-option>
        </el-select>

        <el-button class="ml10" size="small" icon="el-icon-search" @click="searchData()"
                   type="primary">查询
        </el-button>
      </div>
      <div class="flexStart"
           style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
        <el-button class="ml10" type="primary" plain size="small" icon="el-icon-download"
                   @click="exportXxcjXlsx()">导出信息采集表
        </el-button>


        <el-button class="ml10" type="primary" plain size="small" icon="el-icon-download"
                   @click="exportPdfs()">导出自荐表
        </el-button>
      </div>

      <!--      </div>-->
    </el-row>
    <div class="dividerBar"></div>


    <el-table v-tableFit :data="tableData" height="100%" size="small" stripe border
              style="width:fit-content;width:100%;" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column prop="stu_code" label="学号" width="135" align="center"></el-table-column>

      <el-table-column prop="stu_name" label="学生姓名" width="100" align="center"></el-table-column>
      <el-table-column prop="phone" label="性别" width="60" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.gender == 1">男</span>
          <span v-if="scope.row.gender == 2">女</span>
          <span v-if="!scope.row.gender">-</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号" width="135" align="center"></el-table-column>
      <el-table-column prop="class_name" label="班级" width="130" align="center"></el-table-column>
      <el-table-column prop="class_name" label="小组" align="center">
        <template slot-scope="scope">
          <div style="cursor: pointer;" v-for="(cls, idx) in scope.row.class_arr" :key="idx">
            {{ cls.class_name }}
          </div>

        </template>

      </el-table-column>
      <el-table-column prop="sxqx" label="实习去向" width="100" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.sxqxLabel || '-' }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="firm_name" label="实习企业" show-overflow-tooltip min-width="200"
                       align="center"></el-table-column>
      <el-table-column prop="class_name" label="实习周期" min-width="240" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.start_time }}</span>~<span>{{ scope.row.end_time }}</span>

        </template>

      </el-table-column>


      <el-table-column v-if="needSP" label="审核状态" prop="check_status" width="100px" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.sp_status == 0" type="primary">待审核</el-tag>
          <el-tag size="mini" v-if="scope.row.sp_status == 1" type="success">审核通过</el-tag>
          <el-tag size="mini" v-if="scope.row.sp_status == 2" type="danger">审核不通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="$store.state.pmid == 215" fixed="right" label="操作" width="220" align="left">
        <template slot-scope="scope">
          <div>
            <el-tag class="pointer" @click="openXxcj(scope.row)" type="primary" size="mini"
                    style="margin-left: 10px">查看
            </el-tag>
            <el-tag class="pointer" @click="openEditDialog(scope.row,'edit')" type="primary" size="mini"
                    style="margin-left: 10px">修改
            </el-tag>
            <el-tag v-if="needSP&&scope.row.sp_status == 0" class="pointer"
                    @click="openXxcj(scope.row,'check')" type="warning" size="mini"
                    style="margin-left: 10px">审核
            </el-tag>
            <el-tag v-if="scope.row.sp_status != 0" class="pointer" @click="revokeCheck(scope.row)"
                    type="danger" size="mini" style="margin-left: 10px">撤销审核
            </el-tag>
            <el-tag class="pointer" @click="exportPdf(scope.row)" type="success" size="mini"
                    style="margin-left: 10px">导出自荐表
            </el-tag>

          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="$store.state.pmid != 215" fixed="right" label="操作" width="200" align="center">
        <template slot-scope="scope">
          <div>
            <!-- <el-tag class="pointer" @click="openXxcj(scope.row)" type="primary" size="mini"
                style="margin-left: 10px">查看</el-tag> -->
            <el-tag class="pointer" @click="view(scope.row, 0)" type="primary" size="mini"
                    style="margin-left: 10px">信息采集
            </el-tag>
            <el-tag class="pointer" @click="view(scope.row, 1)" type="primary" size="mini"
                    style="margin-left: 10px">生源信息表
            </el-tag>
            <el-tag class="pointer" @click="view(scope.row, 2)" type="primary" size="mini"
                    style="margin-left: 10px">就业推荐表
            </el-tag>
            <el-tag class="pointer" @click="view(scope.row, 3)" type="primary" size="mini"
                    style="margin-left: 10px">毕业去向
            </el-tag>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
                   :current-page="page.current_page" :page-size="page.per_page"
                   :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
                   layout="total, prev, pager, next,sizes, jumper" :total="page.count">
    </el-pagination>


    <xxcj v-if="showXxcj" :stuid="stuid" :isCheck="isCheck" :needSP=needSP></xxcj>
    <editDialog v-if="showEditDialog" :stuid="stuid" :isCheck="isCheck" :needSP=needSP :isEdit="isEdit">
    </editDialog>


    <el-dialog :title="index == 0 ? '信息采集' : (index == 1 ? '生源信息表' : (index == 2 ? '就业推荐表' : '毕业去向'))"
               :visible.sync="showDialog" :close-on-click-modal="false" :destroy-on-close="true" width="800px"
               custom-class="cus_dialog cus_dialogMax" @close="showDialog = false">
      <div v-if="index == 0">
        <table class="dttable" v-if="StuInfo">
          <tr>
            <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">学生基本信息</td>
          </tr>
          <tr>
            <td class="tdheadersml">一寸照片</td>
            <td colspan="3">
              <el-image v-if="StuInfo.photo" style="width: 2.5cm; height: 3.5cm" :src="StuInfo.photo"
                        fit="cover" :preview-src-list="[StuInfo.photo]"></el-image>
              <div v-else>未上传</div>
            </td>
          </tr>
          <tr>
            <td class="tdheadersml">姓名</td>
            <td style="width: 240px">{{ StuInfo.stu_name }}</td>
            <td class="tdheadersml">学号</td>
            <td>{{ StuInfo.stu_code }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">曾用名</td>
            <td>{{ StuInfo.old_name }}</td>
            <td class="tdheadersml">身份证号</td>
            <td>{{ StuInfo.idcard_encrypt }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">出生年月</td>
            <td>{{ StuInfo.birthday }}</td>
            <td class="tdheadersml">性别</td>
            <td>{{ StuInfo.gender == 1 ? "男" : "女" }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">籍贯</td>
            <td>{{ StuInfo.jiguan }}</td>
            <td class="tdheadersml">政治面貌</td>
            <td>{{ StuInfo.zzmm }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">手机号码</td>
            <td>{{ StuInfo.phone }}</td>
            <td class="tdheadersml">父亲电话</td>
            <td>{{ StuInfo.jhr1phone }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">母亲电话</td>
            <td>{{ StuInfo.jhr2phone }}</td>
            <td class="tdheadersml">所在地区</td>
            <td>{{ StuInfo.suozaidi }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">微信号</td>
            <td>{{ StuInfo.weixin }}</td>
            <td class="tdheadersml">家庭邮编</td>
            <td>{{ StuInfo.zip_code }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">电子邮箱</td>
            <td>{{ StuInfo.dzyx }}</td>
            <td class="tdheadersml">健康状况</td>
            <td>{{ StuInfo.health }}</td>
          </tr>
          <tr>

            <td class="tdheadersml">身高(CM)</td>
            <td>{{ StuInfo.height }}</td>
            <td class="tdheadersml">体重(KG)</td>
            <td>{{ StuInfo.weight }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">详细地址</td>
            <td colspan="3">{{ StuInfo.jtzz }}</td>
          </tr>
          <tr>
            <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">学籍信息</td>
          </tr>
          <tr>
            <td class="tdheadersml">所属学校</td>
            <td>{{ StuInfo.platform }}</td>
            <td class="tdheadersml">学校代码</td>
            <td>{{ StuInfo.platform_code }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">所属学院</td>
            <td>{{ StuInfo.xueyuan }}</td>
            <td class="tdheadersml">学校专业</td>
            <td>{{ StuInfo.class_info.zy_name }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">专业代码</td>
            <td>{{ StuInfo.class_info.zy_code }}</td>
            <td class="tdheadersml">专业方向</td>
            <td>{{ StuInfo.class_info.zy_type }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">学制</td>
            <td>{{ StuInfo.class_info.zy_xingzhi }}</td>
            <td class="tdheadersml">学期</td>
            <td>第二学期</td>
          </tr>
          <tr>
            <td class="tdheadersml">所在班级</td>
            <td>{{ StuInfo.class_name }}</td>
            <td class="tdheadersml">入学时间</td>
            <td>{{ StuInfo.ctime }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">毕业时间</td>
            <td>{{ StuInfo.graduate }}</td>
            <td class="tdheadersml">学习形式</td>
            <td>{{ StuInfo.learn }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">委培单位</td>
            <td>{{ StuInfo.commission }}</td>
            <td class="tdheadersml">生源地</td>
            <td>{{ StuInfo.place_of_origin }}</td>
          </tr>
          <tr>
            <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">在校表现</td>
          </tr>
          <tr>
            <td class="tdheadersml">专业主要课程</td>
            <td colspan="3">{{ StuInfo.course }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">担任班干部情况</td>
            <td colspan="3">{{ StuInfo.duties }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">奖惩情况</td>
            <td colspan="3">{{ StuInfo.awards }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">专业技能</td>
            <td colspan="3">
              <div v-for="(item, idx) in StuInfo.skill_json" :key="idx">{{ item }}</div>
            </td>
          </tr>
          <tr>
            <td class="tdheadersml">专业证书</td>
            <td colspan="3">
              <div v-for="(item, idx) in StuInfo.skill_img" :key="idx"
                   style="display: inline-block; vertical-align: top; margin-left: 10px">
                <div>{{ item.name }}</div>

                <el-image v-if="item.file" style="width: 100px" :src="item.file" fit="cover"
                          :preview-src-list="[item.file]"></el-image>
              </div>
            </td>
          </tr>
          <tr>
            <td class="tdheadersml">技能大赛最高奖项</td>
            <td colspan="3">{{ StuInfo.skills_competition }}</td>
          </tr>

          <tr>
            <td class="tdheadersml">外语种类</td>
            <td>{{ StuInfo.efl_type }}</td>
            <td class="tdheadersml">熟练度</td>
            <td>{{ StuInfo.efl_proficiency }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">计算机水平</td>
            <td>{{ StuInfo.computer_level }}</td>
            <td class="tdheadersml"></td>
            <td></td>
          </tr>
          <tr>
            <td class="tdheadersml">特长爱好</td>
            <td colspan="3">{{ StuInfo.specialty }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">个人小结</td>
            <td colspan="3">{{ StuInfo.summary }}</td>
          </tr>

          <tr>
            <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">实习信息</td>
          </tr>

          <tr v-if="sxInfo">
            <td class="tdheadersml">实习去向</td>
            <td>
                            <span v-for="(item, i) in sxqx" v-if="sxInfo.sxqx == item.param_value">{{
                                item.param_desc
                              }}</span>
            </td>
            <td class="tdheadersml">实习企业</td>
            <td>{{ sxInfo.firm_name }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">企业联系人</td>
            <td>{{ sxInfo.firm_content_name ? sxInfo.firm_content_name : '-' }}</td>
            <td class="tdheadersml">联系电话</td>
            <td>{{ sxInfo.firm_content_tel }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">部门</td>
            <td>{{ sxInfo.department }}</td>
            <td class="tdheadersml">岗位</td>
            <td>{{ sxInfo.job }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">指导老师</td>
            <td>{{ sxInfo.mentor_name }}</td>
            <td class="tdheadersml">指导老师电话</td>
            <td>{{ sxInfo.mentor_tel }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">实习薪资</td>
            <td>{{ sxInfo.xinzi }}</td>
            <td class="tdheadersml">薪资范围</td>
            <td>{{ salaryObj[sxInfo.salary] }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">实际上班地址</td>
            <td>{{ sxInfo.address }}</td>
            <td class="tdheadersml">安排方式</td>
            <td>{{ anpaiObj[sxInfo.anpai_type] }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">专业对口情况</td>
            <td>{{ sxInfo.is_ok == 1 ? '对口' : (sxInfo.is_ok == 0 ? '不对口' : '-') }}</td>
            <td class="tdheadersml">有无实习协议</td>
            <td>{{ sxInfo.is_contract == 1 ? '有' : (sxInfo.is_contract == 0 ? '没有' : '-') }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">是否购买保险</td>
            <td>{{ sxInfo.insured == 1 ? '有' : (sxInfo.insured == 0 ? '没有' : '-') }}</td>
            <td class="tdheadersml">有无公积金</td>
            <td>{{ sxInfo.is_housing_fund == 1 ? '有' : (sxInfo.is_housing_fund == 0 ? '没有' : '-') }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">实习开始时间</td>
            <td>{{ sxInfo.start_time }}</td>
            <td class="tdheadersml">实习结束时间</td>
            <td>{{ sxInfo.end_time }}</td>
          </tr>
          <tr v-if="sxInfo.cover && sxInfo.cover.length > 0">
            <td class="tdheadersml">资料附件</td>
            <td colspan="3">
              <div v-for="(item, idx) in sxInfo.cover" :key="idx"
                   style="display: inline-block; vertical-align: top; margin-left: 10px">
                <el-image v-if="item.fileUrl" style="width: 100px" :src="item.fileUrl" fit="cover"
                          :preview-src-list="sxInfo.cover.map(e => { return e.fileUrl })"></el-image>
              </div>
            </td>
          </tr>

          <template v-if="$store.state.pmid != 214 && false">
            <tr>
              <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">就业名册</td>
            </tr>

            <tr v-if="jiuye">
              <td class="tdheadersml">毕业去向</td>
              <td>{{ jiuye.emp_survey_name }}</td>

              <td class="tdheadersml">单位</td>
              <td>{{ jiuye.enter_name || jiuye.school_name }}</td>
            </tr>

            <tr v-if="jiuye">
              <td class="tdheadersml">证明文件</td>
              <td colspan="3">
                <div v-for="(item, idx) in jiuye.files" :key="idx"
                     style="display: inline-block; vertical-align: top; margin-left: 10px">
                  <el-image v-if="item.NEWURL" style="width: 100px" :src="item.NEWURL" fit="cover"
                            :preview-src-list="[item.NEWURL]"></el-image>
                </div>
              </td>
            </tr>

            <tr v-if="jiuyeMore">
              <td class="tdheadersml">单位性质</td>
              <td>{{ jiuye.enterprise_type_name }}</td>

              <td class="tdheadersml">是否对口</td>
              <td>{{ jiuye.counterpart == 1 ? "对口" : "不对口" }}</td>
            </tr>

            <tr v-if="jiuyeMore">
              <td class="tdheadersml">部门</td>
              <td>{{ jiuyeMore.dept_name }}</td>

              <td class="tdheadersml">岗位</td>
              <td>{{ jiuyeMore.job_name }}</td>
            </tr>

            <tr v-if="jiuyeMore2">
              <td class="tdheadersml">专业类别</td>
              <td>{{ jiuye.major_type }}</td>

              <td class="tdheadersml">企业类型</td>
              <td>{{ jiuyeMore2.companyOrgType }}</td>
            </tr>

            <tr v-if="jiuye">
              <td class="tdheadersml">产业类型</td>
              <td>{{ jiuye.industry_type_name }}</td>

              <td class="tdheadersml">地域</td>
              <td>{{ jiuye.area_id_name }}</td>
            </tr>

            <tr v-if="jiuye">
              <td class="tdheadersml">地点</td>
              <td>{{ jiuye.location_name }}</td>

              <td class="tdheadersml">渠道</td>
              <td>{{ jiuye.channel_name }}</td>
            </tr>
            <tr v-if="jiuye">
              <td class="tdheadersml">合同签订情况</td>
              <td>{{ jiuye.contract_name }}</td>

              <td class="tdheadersml">起薪情况</td>
              <td>{{ jiuye.salary_num }}</td>
            </tr>

            <tr v-if="jiuye">
              <td class="tdheadersml">社会保险情况</td>
              <td>{{ shebaoOptions[jiuye.social_security] }}</td>

              <td class="tdheadersml">资格证书情况</td>
              <td>{{ jiuye.certificate == 1 ? "取得" : "未取得" }}</td>
            </tr>
            <tr v-if="jiuye">
              <td class="tdheadersml">就业满意度</td>
              <td>
                <span v-if="jiuye.evaluation == 4">非常满意</span>
                <span v-if="jiuye.evaluation == 3">满意</span>
                <span v-if="jiuye.evaluation == 2">比较满意</span>
                <span v-if="jiuye.evaluation == 1">不满意</span>
                <span v-if="jiuye.evaluation == 0">无法评估</span>
              </td>
            </tr>
          </template>
        </table>
      </div>


      <div v-if="index != 0">
        <iframe :src="iframeUrl" style="width:100%;height:100vh;border:0;overflow:auto"></iframe>
      </div>

    </el-dialog>
    <el-dialog title="导出进度"
               :visible.sync="showProgress" :close-on-click-modal="false" :destroy-on-close="true" width="600px"
               custom-class="cus_dialog cus_dialogMax" @close="showProgress = false" style="height: 300px;">
      <el-progress :text-inside="true" :stroke-width="24" :percentage="progressState" status="success"></el-progress>
      <div style="text-align: center;">{{ progressMsg }}</div>
    </el-dialog>

  </div>
</template>
<script>

import tchtree from "../com/tchTree.vue";
import moment from "moment";
import xxcj from "../sys/components/xxcj.vue";
import editDialog from "./com/editDialog.vue";
import {downloadZip} from "@/utils/downloadZip";

export default {
  components: {
    tchtree, xxcj, editDialog
  },
  data() {
    return {
      visible: false,
      isShowModal: false,
      isShowModalAuth: false,
      showProgress: false,
      progressState: 0,
      progressMsg: "正在生成pdf文件，请稍等....",
      modalTitle: "添加",
      activeRoleID: "",
      biye: 0,
      formData: {},
      formTeacher: {
        bzr: "",
        zdls: []
      },
      ckdTreeData: [],

      defaultProps: {
        label: "name",
        value: "id",
        children: "children",
        emitPath: false,
        checkStrictly: true
      },

      activeName: "CN",
      tableData: [],

      teacherList: [],
      editUser: {},
      deptId: 0,
      editDeptRole: {},

      page: {},
      searchForm: {
        biye: 0,
        ktype: "stu_name",
        keyword: ""
      },
      multipleSelection: [],
      treeData: [],
      firstCascaderProps: {
        label: "name",
        children: "children"
      },
      DataAuth: [],
      student_id: 0,
      showExpand: true,
      dictArr: [],
      schoolYears: [],
      showXxcj: false,
      stuid: 0,
      showDialog: false,
      index: 0,
      StuInfo: null,
      jiuye: null,
      jiuyeMore: null,
      jiuyeMore2: null,
      shebaoOptions: ["没有社保", "五险一金", "三险一金", "五险", "三险"],
      sxqx: [],
      anpaiObj: {
        0: '学校安排', 1: '自主选择', 2: '定向培养'
      },
      qyxzObj: {},
      salaryObj: {},
      sxInfo: null,
      code: '',
      url: "/91job/#",
      iframeUrl: "",
      isCheck: false,
      needSP: false,
      isEdit: false,
      showEditDialog: false,
      deptType: 'xx',
    };
  },
  mounted() {
    let _this = this
    this.getTree();
    this.getSchoolYear().then(_ => {
      _this.getDict().then(rr => {
        _this.getStudents();
      });
    })

    if ($(window).width() < 1670) {
      $(".breadcrumbbox").hide();
    }
    this.getConfig()
    this.getParams();
    setTimeout(() => {
      console.log(589, _this.$store.state.pmid)
      if (window.location.href.indexOf('localhost') > 0 && window.location.href.indexOf('pmid') == -1) {
        window.location.href = `http://localhost:9020/#/collectInformation?pmid=${_this.$store.state.pmid}`
      }
    }, 500)

  },
  methods: {
    getConfig() {
      this.$http.post("/api/sys_sch_config_list", {
        keyword: "91job_code",
        ktype: "ktype"
      }).then(res => {
        for (let item of res.data.data) {
          this.code = item.kvalue
        }
        if (!this.code) {
          this.$message.error("未设置91job学校代码")
        }
      })
      //查看更改是否需要审批，如果要审批，按钮要在提交后隐藏
      this.$http.post("/api/sch_config_one", {ktype: "student_shenpi"}).then(res => {
        if (res.data) {
          if (res.data.kvalue == 1) {
            this.needSP = true
          }
        }
      })
    },
    getParams() {
      this.$http
        .post("/api/sys_param_list", {
          param_type: "'ENTER_INFO','SALARY_RANGE','SHIXI_STATUS'",
          used: 0,
        })
        .then((res) => {
          let sxqx = [];
          let qyxzObj = {};
          let salaryObj = {};
          for (let item of res.data) {
            if (item.param_type == "SHIXI_STATUS") {
              sxqx.push(item);
            }
            if (item.param_type == "ENTER_INFO") {
              qyxzObj[item.param_value] = item.param_desc
            }
            if (item.param_type == "SALARY_RANGE") {
              salaryObj[item.param_value] = item.param_desc
            }
          }
          this.sxqx = sxqx;
          this.qyxzObj = qyxzObj;
          this.salaryObj = salaryObj;
        });
    },
    getSchoolYear() {
      return new Promise((resolve, reject) => {
        if (this.$store.state.pmid == 215) {

          let m = moment().format('M')
          let nowYear = moment().year()
          let schoolYears = []
          if (m >= 5) {
            nowYear++
          }
          for (let i = nowYear; i >= 2019; i--) {
            let s = (i - 1) + "-" + i
            let s_label = s + '学年(' + (i - 5) + '届)'
            schoolYears.push({title: s_label, value: s})
          }
          this.schoolYears = schoolYears
          this.searchForm.school_year = schoolYears[0].value
        }

        resolve()
      })
    },
    getTree() {
      this.$http
        .post("/api/sys_office_tree", {parent_id: 0, biye: this.biye})
        .then(res => {
          this.treeData = res.data.tree;
          this.ckdTreeData = this.treeData;
        });
    },

    searchData() {
      this.page.current_page = 1;
      this.getStudents();
    },

    getDict() {
      return new Promise(resolve => {
        //巡访形式&&巡访情况
        this.$http
          .post("/api/sys_param_list", {param_type: "'SHIXI_STATUS'"})
          .then(res => {
            let dictArr = [];
            res.data.map((item, index, arr) => {
              if (item.param_type == "SHIXI_STATUS") {
                dictArr.push(item);
              }
            });
            this.dictArr = dictArr;
            resolve();
          });
      });
    },
    getStudents() {
      let params = {
        page: this.page.current_page,
        pagesize: this.page.per_page ? this.page.per_page : 20,
        ktype: this.searchForm.ktype,
        keyword: this.searchForm.keyword,
        // dept_id: this.searchForm.keyword ? '' : this.deptId,
        // class_id: this.searchForm.keyword ? '' : this.deptId,
        // office_id: this.searchForm.keyword ? "" : this.deptId,
        class_ids: this.searchForm.keyword ? "" : this.deptId,
        biye: this.searchForm.biye,
        school_year: this.searchForm.school_year,
      };

      // sys_dept_user_related_list
      this.$http.post("/api/t_sch_students_list", params).then(res => {
        res.data.data &&
        res.data.data.map(a => {
          this.dictArr.map(d => {
            if (d.param_value == a.sxqx) {
              a.sxqxLabel = d.param_desc;
              //如果是实习中，检查是否在开始时间
              if (a.sxqx == 1 && a.start_time && a.end_time) {
                let ntime = parseInt(
                  this.$moment(new Date())
                    .add("year", 0)
                    .format("YYYY-MM-DD")
                    .replace(/-/g, "")
                );
                let start_time = parseInt(a.start_time.replace(/-/g, ""));
                let end_time = parseInt(a.end_time.replace(/-/g, ""));
                if (ntime >= start_time && ntime <= end_time) {
                  // a.sxqxLabel = '实习中'
                } else if (ntime > end_time) {
                  a.sxqxLabel = "实习结束";
                } else if (ntime < start_time) {
                  a.sxqxLabel = "待实习";
                }
              }
            }
          });
        });
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },


    handleNodeClick(data) {
      this.deptId = data.id;

      this.deptInfo = data;
      this.ckdTreeData = [data];
      this.getStudents();
    },
    handleSelectionChange(val) {
      let _this = this;
      _this.multipleSelection = val;
    },


    exportPdfs() {
      /*let arr = [];
      if (!this.multipleSelection || this.multipleSelection.length == 0) {
          this.$message.error("请先选择学生");
      } else {
          for (let stu of this.multipleSelection) {
              arr.push({
                  "id": stu.id,
                  "name": stu.stu_name,
                  "class_name": stu.class_name
              });
          }
          if (arr.length > 0) {
              let params = {
                  "stuArr": arr,
                  "pmid": window.pmid,
                  "latest": 1
              }
              this.$http.post(this.$pdfUrl + "/export_zijian", params).then(res => {
                  this.$message({
                      type: 'success',
                      message: '生成成功!'
                  });
                  window.open(res.data.url, "_blank")
              })
          }
      }*/
      this.$http.post('/api/getAllStudents', {
        keyword: this.searchForm.keyword,
        class_ids: this.searchForm.keyword ? "" : this.deptId,
        biye: this.searchForm.biye,
        school_year: this.searchForm.school_year,
      }).then(async res => {
        if (res.data.length) {
          let totalNum = res.data.length
          let exportData = []
          let fileName = "全部学生"
          let tmpObj = {}
          for (let item of res.data) {
            if (this.deptType == 'xx') {
              // 按院系分组
              if (!tmpObj[item.yx_name]) {
                tmpObj[item.yx_name] = {
                  filename: item.yx_name,
                  children: {}
                }
              }
              if (!tmpObj[item.yx_name].children[item.class_name]) {
                tmpObj[item.yx_name].children[item.class_name] = {
                  filename: item.class_name,
                  children: [],
                }
              }
              tmpObj[item.yx_name].children[item.class_name].children.push(item)
            } else if (this.deptType == 'yx' || this.deptType == 'jc') {
              fileName = item.yx_name
              if (!tmpObj[item.class_name]) {
                tmpObj[item.class_name] = {
                  filename: item.class_name,
                  children: []
                }
              }
              tmpObj[item.class_name].children.push(item)
            }
          }
          if (this.deptType == 'bj') {
            fileName = res.data[0].class_name
            exportData = res.data
          } else {
            exportData = Object.values(tmpObj)
          }
          exportData.map(d => {
            if (d.children) {
              d.children = Object.values(d.children)
            }
          })
          this.showProgress = true
          this.progressState = 0
          this.progressMsg = "正在生成pdf文件，请稍等....",
          this.$http.post(this.$pdfUrl + '/export_zijian_batch', {
            stuArr: JSON.stringify(exportData),
            pmid: window.pmid,
            latest: 1,
            fileName: fileName,
          }).then(res => {
            let _that = this
            let timer = setInterval(function () {
              _that.$http.post(_that.$pdfUrl + '/export_zijian_batch', {
                stuArr: JSON.stringify(exportData),
                pmid: window.pmid,
                latest: 1,
                fileName: res.data.filename,
                rdsKey: res.data.rdsKey
              }).then(res => {
                if (res.data.url) {
                  _that.showProgress = false
                  clearInterval(timer)
                  window.open(res.data.url,"_blank")
                } else {
                  if (res.data.progress) {
                    let tmp = res.data.progress.split('-')
                    let s = tmp[1]
                    if (s === 'none' || s === 'done') {
                      let per = tmp[0]
                      if (s === 'done') {
                        per = totalNum
                      }
                      _that.progressState = Math.round(Number(per) / totalNum * 100)
                    } else if (s === 'compress') {
                      _that.progressMsg = '正在压缩文件，请稍等...'
                    }
                  }
                }
              }).catch(e => {
                _that.progressMsg = '压缩失败，请重新下载'
                clearInterval(timer)
              })
            },2000)
          })
        }
      })
    },
    exportPdf(row) {
      let params = {
        "stuArr": [{
          "id": row.id,
          "name": row.stu_name,
          "class_name": row.class_name
        }],
        "pmid": window.pmid,
        "latest": 1
      }

      this.$http.post(this.$pdfUrl + "/export_zijian", params).then(res => {
        this.$message({
          type: 'success',
          message: '生成成功!'
        });
        window.open(res.data.url, "_blank")
      })


    },

    handlePageChange(page) {
      this.page.current_page = page;
      this.getStudents();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getStudents()
    },
    openXxcj(row, type) {
      if (type == 'check') {
        this.isCheck = true
      } else {
        this.isCheck = false
      }

      if (type == 'edit') {
        this.isEdit = true
      } else {
        this.isEdit = false
      }
      this.stuid = row.id
      this.showXxcj = true
    },
    openEditDialog(row, type) {
      if (type == 'edit') {
        this.isEdit = true
      } else {
        this.isEdit = false
      }
      console.log(11111)
      this.stuid = row.id
      this.showEditDialog = true
    },
    revokeCheck(row) {
      this.$confirm('是否确定撤销审核，回到【待审核】状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/stu_xxcj_revoke", {
          id: row.id,
        }).then(res => {
          this.$message({
            type: "success",
            message: "撤销成功!"
          });
          this.getStudents()
        })

      }).catch(() => {

      });
    },
    view(row, index) {
      this.stuid = row.id
      this.index = index
      if (index == 0) {
        this.getIndex0().then(_ => {
          this.showDialog = true
        })
      } else {
        this.$http.post("/api/t_sch_students_form", {
          stu_id: this.stuid,
        }).then((res) => {
          console.log(795, res)
          let resData = res.data
          if (index == 1) {
            if (window.location.href.indexOf("localhost") > 0) {
              this.iframeUrl = "//sx.qushixi.org.cn/91job/#/stusy?xxdm=" + this.code + "&student_codes=" + resData.stu_code
            } else {
              this.iframeUrl = this.url + "/stusy?xxdm=" + this.code + "&student_codes=" + resData.stu_code
            }
            console.log(410, this.iframeUrl)
          } else if (index == 2) {
            if (window.location.href.indexOf("localhost") > 0) {
              this.iframeUrl = "//sx.qushixi.org.cn/91job/#/stujytjb?xxdm=" + this.code + "&student_codes=" + resData.stu_code
            } else {
              this.iframeUrl = this.url + "/stujytjb?xxdm=" + this.code + "&student_codes=" + resData.stu_code
            }
          } else if (index == 3) {
            if (window.location.href.indexOf("localhost") > 0) {
              this.iframeUrl = "//sx.qushixi.org.cn/91job/#/spinfo?xxdm=" + this.code + "&student_codes=" + resData.stu_code
            } else {
              this.iframeUrl = this.url + "/spinfo?xxdm=" + this.code + "&student_codes=" + resData.stu_code
            }
          }
          this.showDialog = true

        })
      }

    },
    getIndex0() {
      return new Promise((resolve, reject) => {
        this.$http.post("/api/t_sch_students_form", {
          stu_id: this.stuid,
        }).then((res) => {
          if (res.data.course) {
            res.data.course = JSON.parse(res.data.course).join("，");
          }
          if (res.data.duties) {
            res.data.duties = JSON.parse(res.data.duties).join("，");
          }
          if (res.data.awards) {
            try {
              res.data.awards = JSON.parse(res.data.awards).join("，");
            } catch (error) {
              res.data.awards = res.data.awards;
            }
          }
          if (res.data.skill_json) {
            res.data.skill_json = JSON.parse(res.data.skill_json);
          }
          if (res.data.skill_img) {
            res.data.skill_img = JSON.parse(res.data.skill_img);
          }
          if (res.data.skills_competition) {
            res.data.skills_competition = JSON.parse(res.data.skills_competition).join(
              "。"
            );
          }

          this.$http
            .post("/api/t_sch_students_relate", {
              stu_id: this.stuid,
            })
            .then((res2) => {
              for (let k in res2.data) {
                res.data[k] = res2.data[k];
              }
              this.StuInfo = res.data;
              console.log(854, this.StuInfo)

            });

          this.$http
            .post("/api/jydc_info_by_stu_id", {
              stu_id: this.stuid,
            })
            .then((res2) => {
              if (res2.data.files) {
                res2.data.files = JSON.parse(res2.data.files);
              }
              this.jiuye = res2.data || {};
            });

          this.$http
            .post("/api/find_info_by_stu_id", {
              stu_id: this.stuid,
            })
            .then((res2) => {
              if (res2.data.files) {
                res2.data.files = JSON.parse(res2.data.files);
              }
              this.jiuyeMore = res2.data;

              this.$http
                .post("/api/tyc_search", {
                  enter_name: res2.data.enter_name,
                })
                .then((res3) => {
                  this.jiuyeMore2 = res3.data;
                });
            });

          this.$http
            .post("/api/t_sch_stu_enter_from", {id: res.data.stu_enter_id})
            .then((sx) => {
              let sxDate = sx.data
              sxDate.cover = sxDate.cover ? JSON.parse(sxDate.cover) : "";
              this.sxInfo = sxDate;
            });
          resolve()
        });
      })
    },
    exportXxcjXlsx() {
      if (this.deptId) {
        this.down({
          class_ids: this.deptId,
        })
      } else {
        this.$confirm('未选择班级，是否按照当前所选学年导出?', '提示', {
          confirmButtonText: '确定导出',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.down({
            school_year: this.searchForm.school_year,
          })
        }).catch(() => {

        });
      }


    },
    down(params) {
      this.$http.post("/api/down_xxcj_xlsx", params).then(res => {
        if (res.data.url) {
          window.open(res.data.url, "_blank")
        }
      });
    },
    changeType(val) {
      this.deptType = val
    },
  }
};
</script>
<style scoped lang="less" type="text/less">
.app-container {
  .treeContainer {
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(220, 223, 230, 0.6);
    margin-right: 20px;
    padding-right: 10px;
    overflow: auto;

    .treeTitle {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #409eff;
      padding-left: 6px;
    }
  }
}

.nodetag {
  display: inline-block;
  padding: 0 5px;
  font-size: 12px;
  transform: scale(0.8, 0.8);
  background-color: #409eff;
  color: #fff;
  border-radius: 4px;
}
</style>
