<template>
    <div>
        <el-dialog title="信息采集修改" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
            width="1000px" custom-class="cus_dialog cus_dialogMax" @close="closeDialog">


            <div v-if="activeName == 'first'" class="dialog_right">
                <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                    <span></span>
                    <span style="font-weight: 400">基本信息/学籍信息</span>
                </div>
                <div class="flexStart"
                    style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                    <template v-for="(item, index) in base_json">
                        <div v-if="item.show == 1" class="lineRow flexStretch"
                            :style="list_w100.indexOf(item.name) > -1 ? 'width:100%;' : 'width:50%;'">
                            <div class="cell flexStart" :class="item.required ? 'required' : ''"
                                style="min-height: 40px;height: unset;">{{ item.name }}</div>
                            <div v-if="item.name == '性别'" class="cell flexStart flex_1"
                                style="height: unset;min-height: 40px;flex-wrap:wrap;">
                                <el-input class="input-with-select" :disabled="item.edit ? false : true"
                                    :value="stuInfo[item.column] == 1 ? '男' : '女'" size="small" :placeholder="item.name"
                                    style="width:100%;font-size:16px;"></el-input>

                            </div>
                            <div v-else-if="item.name == '一寸照片'" class="cell flexStart flex_1"
                                style="height: unset;min-height: 40px;flex-wrap:wrap;padding:6px;">

                                <div v-if="stuInfo[item.column]"
                                    style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;">
                                    <el-image style="width: 100px; height: 100px" :src="stuInfo[item.column]"
                                        :preview-src-list="[stuInfo[item.column]]" fit="contain"></el-image>

                                    <div class="imgremove" @click="removeImg(index, item.column)"><i
                                            class="el-icon-remove"></i>
                                    </div>
                                </div>

                                <div v-if="!stuInfo[item.column]" style="display:inline-block;vertical-align: top;">

                                    <el-upload style="display: inline-block; margin-right: 20px" action=""
                                        :http-request="uploadOss"
                                        :on-success="(e) => { uploadCoverFiles(e, index, item.column) }"
                                        :show-file-list="false" name="image" accept="image/png, image/jpeg">
                                        <div class="imgaddbtn">
                                            ＋
                                        </div>
                                    </el-upload>
                                </div>
                            </div>

                            <div v-else-if="item.name == '入学时间' || item.name == '毕业时间'" class="cell flexStart flex_1"
                                style="height: unset;min-height: 40px;flex-wrap:wrap;">
                                <el-date-picker v-model="stuInfo[item.column]" type="date" value-format="yyyy-MM-dd"
                                    placeholder="选择日期" :clearable="false" :disabled="item.edit ? false : true">
                                </el-date-picker>
                            </div>


                            <div v-else-if="item.name == '所在地区'" class="cell flexStart flex_1"
                                style="height: unset;min-height: 40px;flex-wrap:wrap;">
                                <el-cascader ref="fromProvinceCascader" v-model="szd_code" style="width:100%;"
                                    @change="(e) => { selectCity(e, item.column) }" :props="treeProps" clearable
                                    :options="areas" :disabled="item.edit ? false : true" :placeholder="item.name">
                                </el-cascader>
                            </div>

                            <div v-else-if="item.name == '生源地'" class="cell flexStart flex_1"
                                style="height: unset;min-height: 40px;flex-wrap:wrap;">
                                <el-cascader ref="fromProvinceCascader_syd" v-model="syd_code" style="width:100%;"
                                    @change="(e) => { setShengYuandi(e, item.column) }" :props="treeProps_syd" clearable
                                    :options="areas_syd" :disabled="item.edit ? false : true" :placeholder="item.name">
                                </el-cascader>
                            </div>

                            <div v-else-if="item.name == '在校期间担任学校的主要职务情况' || item.name == '奖惩情况' || item.name == '专业技能' || item.name == '专业证书' || item.name == '技能大赛最高奖项' || item.name == '校级及以上奖励' || item.name == '特长爱好' || item.name == '个人小结'"
                                class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;">
                                <el-input class="input-with-select" :disabled="item.edit ? false : true"
                                    v-model="stuInfo[item.column]" size="small" :placeholder="item.name" type="textarea"
                                    style="width:100%;font-size:16px;"></el-input>
                            </div>
                            <div v-else class="cell flexStart flex_1"
                                style="height: unset;min-height: 40px;flex-wrap:wrap;">
                                <el-input class="input-with-select" :disabled="item.edit ? false : true"
                                    v-model="stuInfo[item.column]" size="small" :placeholder="item.name"
                                    style="width:100%;font-size:16px;"></el-input>
                            </div>

                        </div>
                    </template>


                </div>

                <table class="dttable" v-if="StuInfo && false">
                    <tr>
                        <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">基本信息</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">一寸照片</td>
                        <td colspan="3">
                            <el-image v-if="StuInfo.photo" style="width: 2.5cm; height: 3.5cm" :src="StuInfo.photo"
                                fit="cover" :preview-src-list="[StuInfo.photo]"></el-image>
                            <div v-else>未上传</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">姓名</td>
                        <td style="width: 240px">{{ StuInfo.stu_name }}</td>
                        <td class="tdheadersml">学号</td>
                        <td>{{ StuInfo.stu_code }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">曾用名</td>
                        <td>{{ StuInfo.old_name }}</td>
                        <td class="tdheadersml">身份证号</td>
                        <td>{{ StuInfo.idcard_encrypt }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">出生年月</td>
                        <td>{{ StuInfo.birthday }}</td>
                        <td class="tdheadersml">性别</td>
                        <td>{{ StuInfo.gender == 1 ? "男" : "女" }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">籍贯</td>
                        <td>{{ StuInfo.jiguan }}</td>
                        <td class="tdheadersml">政治面貌</td>
                        <td>{{ StuInfo.zzmm }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">手机号码</td>
                        <td>{{ StuInfo.phone }}</td>
                        <td class="tdheadersml">父亲电话</td>
                        <td>{{ StuInfo.jhr1phone }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">母亲电话</td>
                        <td>{{ StuInfo.jhr2phone }}</td>
                        <td class="tdheadersml">所在地区</td>
                        <td>{{ StuInfo.suozaidi }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">微信号</td>
                        <td>{{ StuInfo.weixin }}</td>
                        <td class="tdheadersml">家庭邮编</td>
                        <td>{{ StuInfo.zip_code }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">电子邮箱</td>
                        <td>{{ StuInfo.dzyx }}</td>
                        <td class="tdheadersml">健康状况</td>
                        <td>{{ StuInfo.health }}</td>
                    </tr>
                    <tr>

                        <td class="tdheadersml">身高(CM)</td>
                        <td>{{ StuInfo.height }}</td>
                        <td class="tdheadersml">体重(KG)</td>
                        <td>{{ StuInfo.weight }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">详细地址</td>
                        <td colspan="3">{{ StuInfo.jtzz }}</td>
                    </tr>
                    <tr>
                        <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">学籍信息</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">所属学校</td>
                        <td>{{ StuInfo.platform }}</td>
                        <td class="tdheadersml">学校代码</td>
                        <td>{{ StuInfo.platform_code }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">所属学院</td>
                        <td>{{ StuInfo.xueyuan }}</td>
                        <td class="tdheadersml">学校专业</td>
                        <td>{{ StuInfo.class_info.zy_name }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">专业代码</td>
                        <td>{{ StuInfo.class_info.zy_code }}</td>
                        <td class="tdheadersml">专业方向</td>
                        <td>{{ StuInfo.class_info.zy_type }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">学制</td>
                        <td>{{ StuInfo.class_info.zy_xingzhi }}</td>
                        <td class="tdheadersml">学期</td>
                        <td>第二学期</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">所在班级</td>
                        <td>{{ StuInfo.class_name }}</td>
                        <td class="tdheadersml">入学时间</td>
                        <td>{{ StuInfo.ctime }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">毕业时间</td>
                        <td>{{ StuInfo.graduate }}</td>
                        <td class="tdheadersml">学习形式</td>
                        <td>{{ StuInfo.learn }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">委培单位</td>
                        <td>{{ StuInfo.commission }}</td>
                        <td class="tdheadersml">生源地</td>
                        <td>{{ StuInfo.place_of_origin }}</td>
                    </tr>
                    <tr>
                        <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">在校表现</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">专业主要课程</td>
                        <td colspan="3">{{ StuInfo.course }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">担任班干部情况</td>
                        <td colspan="3">{{ StuInfo.duties }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">奖惩情况</td>
                        <td colspan="3">{{ StuInfo.awards }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">专业技能</td>
                        <td colspan="3">
                            <div v-for="(item, idx) in StuInfo.skill_json" :key="idx">{{ item }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">专业证书</td>
                        <td colspan="3">
                            <div v-for="(item, idx) in StuInfo.skill_img" :key="idx"
                                style="display: inline-block; vertical-align: top; margin-left: 10px">
                                <div>{{ item.name }}</div>

                                <el-image v-if="item.file" style="width: 100px" :src="item.file" fit="cover"
                                    :preview-src-list="[item.file]"></el-image>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">技能大赛最高奖项</td>
                        <td colspan="3">{{ StuInfo.skills_competition }}</td>
                    </tr>

                    <tr>
                        <td class="tdheadersml">外语种类</td>
                        <td>{{ StuInfo.efl_type }}</td>
                        <td class="tdheadersml">熟练度</td>
                        <td>{{ StuInfo.efl_proficiency }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">计算机水平</td>
                        <td>{{ StuInfo.computer_level }}</td>
                        <td class="tdheadersml"></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">特长爱好</td>
                        <td colspan="3">{{ StuInfo.specialty }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">个人小结</td>
                        <td colspan="3">{{ StuInfo.summary }}</td>
                    </tr>

                    <tr>
                        <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">实习信息</td>
                    </tr>

                    <tr v-if="sxInfo">
                        <td class="tdheadersml">实习去向</td>
                        <td>
                            <span v-for="(item, i) in sxqx" v-if="sxInfo.sxqx == item.param_value">{{
                                item.param_desc
                                }}</span>
                        </td>
                        <td class="tdheadersml">实习企业</td>
                        <td>{{ sxInfo.firm_name }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">企业联系人</td>
                        <td>{{ sxInfo.firm_content_name }}</td>
                        <td class="tdheadersml">联系电话</td>
                        <td>{{ sxInfo.firm_content_tel }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">部门</td>
                        <td>{{ sxInfo.department }}</td>
                        <td class="tdheadersml">岗位</td>
                        <td>{{ sxInfo.job }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">指导老师</td>
                        <td>{{ sxInfo.mentor_name }}</td>
                        <td class="tdheadersml">指导老师电话</td>
                        <td>{{ sxInfo.mentor_tel }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">实习薪资</td>
                        <td>{{ sxInfo.xinzi }}</td>
                        <td class="tdheadersml">薪资范围</td>
                        <td>{{ salaryObj[sxInfo.salary] }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">实际上班地址</td>
                        <td>{{ sxInfo.address }}</td>
                        <td class="tdheadersml">安排方式</td>
                        <td>{{ anpaiObj[sxInfo.anpai_type] }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">专业对口情况</td>
                        <td>{{ sxInfo.is_ok == 1 ? '对口' : (sxInfo.is_ok == 0 ? '不对口' : '-') }}</td>
                        <td class="tdheadersml">有无实习协议</td>
                        <td>{{ sxInfo.is_contract == 1 ? '有' : (sxInfo.is_contract == 0 ? '没有' : '-') }}</td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">是否购买保险</td>
                        <td>{{ sxInfo.insured == 1 ? '有' : (sxInfo.insured == 0 ? '没有' : '-') }}</td>
                        <td class="tdheadersml">有无公积金</td>
                        <td>{{ sxInfo.is_housing_fund == 1 ? '有' : (sxInfo.is_housing_fund == 0 ? '没有' : '-') }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tdheadersml">实习开始时间</td>
                        <td>{{ sxInfo.start_time }}</td>
                        <td class="tdheadersml">实习结束时间</td>
                        <td>{{ sxInfo.end_time }}</td>
                    </tr>
                    <tr v-if="sxInfo && sxInfo.cover && sxInfo.cover.length > 0">
                        <td class="tdheadersml">资料附件</td>
                        <td colspan="3">
                            <div v-for="(item, idx) in sxInfo.cover" :key="idx"
                                style="display: inline-block; vertical-align: top; margin-left: 10px">
                                <el-image v-if="item.fileUrl" style="width: 100px" :src="item.fileUrl" fit="cover"
                                    :preview-src-list="sxInfo.cover.map(e => { return e.fileUrl })"></el-image>
                            </div>
                        </td>
                    </tr>




                    <template v-if="needSP">
                        <tr>
                            <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">审核状态
                            </td>
                        </tr>

                        <tr>
                            <td class="tdheadersml">审批状态</td>
                            <td colspan="3">{{ StuInfo.sp_status == 0 ? '待审批' : '' }}
                                {{ StuInfo.sp_status == 1 ? '审批通过' : '' }}
                                {{ StuInfo.sp_status == 2 ? '审批拒绝' : '' }}
                            </td>
                        </tr>
                        <tr v-if="StuInfo.sp_status != 0">
                            <td class="tdheadersml">审批意见</td>
                            <td colspan="3">{{ StuInfo.sp_word || '-' }}</td>
                        </tr>
                    </template>
                </table>
            </div>
            <div slot="footer">
                <el-button size="small" type="primary" @click="submitForm()">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import moment from "moment";
import aes from "../../../utils/aes.js";
import area from "../../../utils/arae_value.js"

export default {
    name: "editDialog",
    props: ["stuid", "isCheck", "needSP", 'isEdit'],
    data() {
        return {
            activeName: "first",
            isShowModal: true,
            StuInfo: { class_info: {} },
            jiuye: {},
            jiuyeMore: {},
            jiuyeMore2: {},
            shebaoOptions: ["没有社保", "五险一金", "三险一金", "五险", "三险"],
            sxqx: [],
            anpaiObj: {
                0: '学校安排', 1: '自主选择', 2: '定向培养'
            },
            qyxzObj: {},
            salaryObj: {},
            sxInfo: { cover: [] },
            code: '',
            url: "/91job/#",
            iframeUrl: "",
            spword: '',
            base_json: [],
            stuInfo: {},
            treeProps: {
                children: 'children',
                label: 'label',
                value: 'value',
                emitPath: true,
            },
            treeProps_syd: {
                children: 'children',
                label: 'label',
                value: 'label',
                emitPath: true,
            },
            // 所在地
            areas: [],
            szd_code: [],
            // 生源地
            areas_syd: [],
            syd_code: [],
            list_w100: ['一寸照片', '在校期间担任学校的主要职务情况' ,'奖惩情况', '专业技能', '专业证书' , '技能大赛最高奖项', '校级及以上奖励' ,'特长爱好', '个人小结'],
        };
    },
    mounted() {

        if (area[0].label == '全国') {
            area.shift()
        }
        this.areas = area

        let area_syd = JSON.parse(JSON.stringify(area))
        for (let p of area_syd) {
            if (p.children) {
                for (let c of p.children) {
                    delete c.children
                }
            }
        }
        this.areas_syd = area_syd

        // console.log(421, this.areas)
        this.getConfig()
        this.getParams();
        this.getxxcjConfig()
    },
    methods: {
        getConfig() {
            this.$http.post("/api/sys_sch_config_list", {
                keyword: "91job_code",
                ktype: "ktype"
            }).then(res => {
                for (let item of res.data.data) {
                    this.code = item.kvalue
                }
                if (!this.code) {
                    this.$message.error("未设置91job学校代码")
                }
            })
        },
        getxxcjConfig() {
            this.$http.post("/api/get_xxcj_config").then(res => {
                if (res.data && res.data.base_json) {
                    this.base_json = JSON.parse(res.data.base_json)

                    this.getDictory()
                }
            })
        },
        getDictory() {
            this.$http.post("/api/sys_param_list", {
                param_type:
                    "'NATION','POLITICAL_STATUS','SKILL_TYPE','SALARY_RANGE','HEALTH','LANGUAGE','lng_level','xuexixingshi','peiyangfangshi','COMPUTER_LEVEL'"

            }).then((res) => {
                let dic = {}
                for (let item of res.data) {
                    if (!dic[item.param_type]) {
                        dic[item.param_type] = [item]
                        dic[item.param_type + "_index"] = -1
                    } else {
                        dic[item.param_type].push(item)
                    }
                }
                this.DicList = dic

                this.getInfo()

            })
        },

        getInfo() {
            this.$http.post('/api/t_sch_students_form', { stu_id: this.stuid }).then((res) => {
                this.$http.post('/api/t_sch_students_relate', { stu_id: this.stuid }).then((more) => {
                    if (more && more.data) {
                        for (let k in more.data) {
                            res.data[k] = more.data[k]
                        }
                    }




                    let stuInfo = res.data;

                    if (res.data.idcard_type == 0 && res.data.idcard_encrypt) {
                        res.data.identity_card = aes.decrypt(res.data.identity_card)

                        this.showIdCardInput = true
                        // if (!rData.birthday) {
                        //   _this.getBirthdayFromIdCard(id_num)
                        // }
                        // if (!rData.jiguan) {
                        //   rData.jiguan = _this.getHometownFromIdCard(id_num)
                        // }
                    }
                    let DicList = this.DicList
                    let base_json = this.base_json



                    //政治面貌填写了就不能改了
                    if (stuInfo.zzmm) {
                        for (let item of base_json) {
                            if (item.name == "政治面貌") {
                                item.edit = 0
                                break
                            }
                        }
                    }


                    //检查默认值
                    for (let item of base_json) {

                        if (stuInfo[item.column]) {//设置选中值

                            if (DicList[item.dic_name]) {
                                for (let i = 0; i < DicList[item.dic_name].length; i++) {
                                    if (DicList[item.dic_name][i].param_desc == stuInfo[item.column]) {
                                        DicList[item.dic_name + "_index"] = i//给下拉框设置选中
                                        break
                                    }
                                }
                            }
                        } else {
                            if (item.default) {

                                if (DicList[item.dic_name]) {
                                    for (let i = 0; i < DicList[item.dic_name].length; i++) {
                                        if (DicList[item.dic_name][i].param_desc == item.default) {
                                            DicList[item.dic_name + "_index"] = i//给下拉框设置默认值
                                            stuInfo[item.column] = item.default
                                            break
                                        }
                                    }
                                } else {

                                    stuInfo[item.column] = item.default
                                }
                            }
                        }

                    }

                    if (stuInfo['suozaidi_code']) {

                        stuInfo['suozaidi_code'] = JSON.parse(stuInfo['suozaidi_code'])
                        this.szd_code = stuInfo['suozaidi_code']

                        // if (stuInfo['suozaidi_code'] && stuInfo['suozaidi_code'].length > 0) {
                        //     this.szd_code = stuInfo['suozaidi_code']
                        //     // this.szd_code = stuInfo['suozaidi_code'][stuInfo['suozaidi_code'].length - 1]
                        // }

                    } else {
                        stuInfo['suozaidi_code'] = []
                    }

                    if (stuInfo.place_of_origin) {
                        stuInfo.place_of_origin = stuInfo.place_of_origin.split(',')
                        this.syd_code = [stuInfo['place_of_origin'][0], stuInfo['place_of_origin'][1]]
                    } else {
                        stuInfo.place_of_origin = [];
                    }
                    if (stuInfo.photo == "undefined") {
                        stuInfo.photo = ""
                    }
                    //政治面貌，填写了不能修改
                    this.stuInfo = stuInfo
                    this.DicList = DicList
                    this.loading = false
                    this.base_json = base_json
                    // console.log('this.stuInfo', this.stuInfo)
                    // console.log('this.base_json', this.base_json)
                })
            })
        },
        getInfo1() {
            this.$http
                .post("/api/t_sch_students_form", {
                    stu_id: this.stuid,
                })
                .then((res) => {
                    if (res.data.course) {
                        res.data.course = JSON.parse(res.data.course).join("，");
                    }
                    if (res.data.duties) {
                        res.data.duties = JSON.parse(res.data.duties).join("，");
                    }
                    if (res.data.awards) {
                        try {
                            res.data.awards = JSON.parse(res.data.awards).join("，");
                        } catch (error) {
                            res.data.awards = res.data.awards;
                        }
                    }
                    if (res.data.skill_json) {
                        res.data.skill_json = JSON.parse(res.data.skill_json);
                    }
                    if (res.data.skill_img) {
                        res.data.skill_img = JSON.parse(res.data.skill_img);
                    }
                    if (res.data.skills_competition) {
                        res.data.skills_competition = JSON.parse(res.data.skills_competition).join(
                            "。"
                        );
                    }

                    this.$http
                        .post("/api/t_sch_students_relate", {
                            stu_id: this.stuid,
                        })
                        .then((res2) => {
                            for (let k in res2.data) {
                                res.data[k] = res2.data[k];
                            }
                            res.data.class_info = res.data.class_info ? res.data.class_info : {}

                            this.StuInfo = res.data;
                        });

                    this.$http
                        .post("/api/jydc_info_by_stu_id", {
                            stu_id: this.stuid,
                        })
                        .then((res2) => {
                            if (res2.data.files) {
                                res2.data.files = JSON.parse(res2.data.files);
                            }
                            this.jiuye = res2.data;
                        });

                    this.$http
                        .post("/api/find_info_by_stu_id", {
                            stu_id: this.stuid,
                        })
                        .then((res2) => {
                            if (res2.data.files) {
                                res2.data.files = JSON.parse(res2.data.files);
                            }
                            this.jiuyeMore = res2.data;

                            this.$http
                                .post("/api/tyc_search", {
                                    enter_name: res2.data.enter_name,
                                })
                                .then((res3) => {
                                    this.jiuyeMore2 = res3.data;
                                });
                        });

                    this.$http
                        .post("/api/t_sch_stu_enter_from", { id: res.data.stu_enter_id })
                        .then((sx) => {
                            let sxDate = sx.data
                            sxDate.cover = sxDate.cover && this.isJSON(sxDate.cover) ? JSON.parse(sxDate.cover) : [];
                            this.sxInfo = sxDate;
                        });
                });

        },
        getParams() {
            this.$http
                .post("/api/sys_param_list", {
                    param_type: "'ENTER_INFO','SALARY_RANGE','SHIXI_STATUS'",
                    used: 0,
                })
                .then((res) => {
                    let sxqx = [];
                    let qyxzObj = {};
                    let salaryObj = {};
                    for (let item of res.data) {
                        if (item.param_type == "SHIXI_STATUS") {
                            sxqx.push(item);
                        }
                        if (item.param_type == "ENTER_INFO") {
                            qyxzObj[item.param_value] = item.param_desc
                        }
                        if (item.param_type == "SALARY_RANGE") {
                            salaryObj[item.param_value] = item.param_desc
                        }
                    }
                    this.sxqx = sxqx;
                    this.qyxzObj = qyxzObj;
                    this.salaryObj = salaryObj;
                });
        },
        selectCity(e, column) {
            // console.log(652, e, column, this.$refs.fromProvinceCascader[0].getCheckedNodes()[0].pathLabels)
            let suozaidi = this.$refs.fromProvinceCascader[0].getCheckedNodes()[0].pathLabels
            this.stuInfo['suozaidi'] = suozaidi.join('/')
            this.stuInfo['suozaidi_code'] = e
        },
        setShengYuandi(e, column) {
            let place_of_origin = this.$refs.fromProvinceCascader_syd[0].getCheckedNodes()[0].pathLabels
            this.stuInfo['place_of_origin'] = place_of_origin.join(',')
        },
        uploadCoverFiles(e, index, column) {
            this.stuInfo[column] = e.src || ''
        },
        removeImg(index, column) {
            this.stuInfo[column] = ''
        },
        submitForm(st) {
            let formData = JSON.parse(JSON.stringify(this.stuInfo))
            let eData = JSON.parse(JSON.stringify(this.stuInfo))
            let rdata = {}
            for (let k in formData) {
                if (isNaN(k)) {
                    for (let item of this.base_json) {
                        if (item.column == k) {
                            if (item.required && !item.relate && !formData[k]) {
                                this.$message.error(item.name + "不能为空")
                                return
                            } else {
                                if ((formData[k] || formData[k] === '') && !item.relate) {
                                    rdata[k] = formData[k]
                                }

                            }
                            break
                        }

                    }
                }
            }

            for (let item of this.base_json) {
                if (item.column == "photo" && item.required && !eData.photo) {
                    this.$message.error(item.name + "不能为空")
                    return
                }

            }

            if (this.stuInfo.suozaidi) {
                rdata["suozaidi"] = this.stuInfo.suozaidi
                rdata["suozaidi_code"] = JSON.stringify(this.stuInfo.suozaidi_code)
            }
            if (this.stuInfo.place_of_origin && this.stuInfo.place_of_origin.length > 0 && typeof this.stuInfo.place_of_origin == 'object') {
                rdata['place_of_origin'] = this.stuInfo.place_of_origin.join(',')
            }
            

            //检查email是否合法
            let myreg2 = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
            if (rdata["dzyx"]) {
                if (!myreg2.test(rdata["dzyx"])) {
                    this.$message.error('请输入正确的邮箱');
                    return
                }
            }

            //检查手机号是否合法
            let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (rdata["phone"]) {
                if (!phone.test(rdata["phone"])) {
                    this.$message.error('请输入正确的手机号码');
                    return
                }
            }
            if (rdata["jhr1phone"]) {
                if (!phone.test(rdata["jhr1phone"])) {
                    this.$message.error('请输入正确的父亲手机号码');
                    return
                }
            }

            if (rdata["jhr2phone"]) {
                if (!phone.test(rdata["jhr2phone"])) {
                    this.$message.error('请输入正确的母亲手机号码');
                    return
                }
            }

            if (rdata["height"]) {
                if (isNaN(rdata["height"])) {
                    this.$message.error('身高只能输入数字【单位：厘米】');
                    return
                }
            }
            if (rdata["weight"]) {
                if (isNaN(rdata["weight"])) {
                    this.$message.error('体重只能输入数字【单位：千克】');
                    return
                }
            }



            this.$http.post("/api/students_xxcj", {
                stu_id: this.stuid,
                rdata: rdata
            }).then(res => {
                this.$message({
                    type: "success",
                    message: "保存成功!"
                });
                this.$$parent(this, "getStudents");
                this.$$parent(this, "showEditDialog", false);
            })
        },
        closeDialog() {
            this.$$parent(this, "showEditDialog", false);
        },
    },
};
</script>

<style scoped lang="less">
/deep/ .is-disabled .el-input__inner {
    color: #606266;
}

.dialog_right {
    .lineRow {
        .cell:first-of-type {
            width: 120px;
            padding-left: 8px;
        }

    }

    .imgaddbtn {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px dotted #ccc;
        font-size: 30px;
        cursor: pointer;
    }

    .imgremove {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;

        cursor: pointer;
        position: absolute;
        top: -5px;
        right: -5px;
        color: #ccc;
    }

    .imgremove:hover {
        color: orange;
    }


}
</style>